<template>
  <div class="p-4" v-if="showForm">
    {{ titleForm }}
    <br />
    <br />
    <BaseForm
      :formElements="formElements"
      @form:onSubmit="value => saveStudy(value)"
      :isLoading="isLoading"
    >
      <template #elements>
        <BaseFormElement
          :key="key"
          v-for="(element, key) in formElements"
          :elements="formElements"
          :element="element"
          v-model="element.data.value"
        >
          <template #after>
            <component :is="element.after" />
          </template>
        </BaseFormElement>
      </template>
    </BaseForm>
  </div>

  <div v-else>
    Loading
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import BaseForm from '@/components/form/BaseForm.vue'
import BaseFormElement from '@/components/form/BaseFormElement.vue'
import { formElements as StudyFormElements } from '@/config/StudyFormElements.ts'
import syncValues from '@/utils/syncValues.ts'

// Studies store
import { STUDIES_STORE_NAME } from '@/store/modules/studies'
import {
  CREATE_STUDY,
  UPDATE_STUDY
} from '@/store/modules/studies/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

export default {
  components: { BaseForm, BaseFormElement },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const studies = computed(() => store.getters[STUDIES_STORE_NAME + '/data'])
    const formElements = ref(StudyFormElements())

    const isNewStudy = computed(() => props.id === null)
    const titleForm = computed(() =>
      isNewStudy.value ? 'Nuevo estudio' : 'Editar estudio'
    )
    const existStudy = computed(() => studies?.value?.[props.id])
    const showForm = computed(() => isNewStudy.value || existStudy.value)

    const isLoading = computed(
      () => store.getters[STUDIES_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[STUDIES_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[STUDIES_STORE_NAME + '/isReady']
    )

    async function saveStudy(data) {
      if (props.id) {
        data.id = props.id
      }
      const action = isNewStudy.value ? CREATE_STUDY : UPDATE_STUDY
      await store.dispatch(STUDIES_STORE_NAME + '/' + action, data)
      if (isReady.value) {
        router.push({ name: 'studies' })

        pushNotification({
          title: isNewStudy.value
            ? 'Creado'
            : 'Actualizado' + ' correctamente!',
          desciption: 'Tu estudio se ha guardado correctamente ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    watch(studies, value => {
      if (value && props.id) {
        if (!existStudy.value) {
          router.push({ name: 'studies' })
        }
        if (existStudy.value) {
          formElements.value = syncValues(formElements.value, existStudy.value)
        }
      }
    })

    if (existStudy.value) {
      formElements.value = syncValues(formElements.value, existStudy.value)
    }

    return { formElements, isLoading, saveStudy, titleForm, showForm }
  }
}
</script>
