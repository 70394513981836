import Input from '@/interfaces/inputs/Input'
import Checkbox from '@/interfaces/inputs/Checkbox'
import FormElement from '@/interfaces/form/FormElement'

const image = (): Input => ({
  id: 'image',
  label: 'Logo',
  type: 'file',
  value: null
})

const institute = (): Input => ({
  id: 'institute',
  label: 'Instituto',
  type: 'text',
  value: null,
  required: true
})

const title = (): Input => ({
  id: 'title',
  label: 'Titulo',
  type: 'text',
  value: null,
  required: true
})

const startDate = (): Input => ({
  id: 'startDate',
  label: 'Fecha de inicio',
  type: 'date',
  value: null,
  required: true
})

const current = (): Checkbox => ({
  id: 'current',
  label: 'Actualmente estudiando',
  type: 'checkbox',
  value: false
})

const finishDate = (): Input => ({
  id: 'finishDate',
  label: 'Fecha de finalizacion',
  type: 'date',
  value: null,
  required: true
})

export const formElements = (): FormElement[] => [
  {
    type: 'input',
    data: image()
  },
  {
    type: 'input',
    data: institute()
  },
  {
    type: 'input',
    data: title()
  },
  {
    type: 'input',
    data: startDate()
  },

  {
    type: 'input',
    data: current()
  },
  {
    type: 'input',
    data: finishDate(),
    isVisible: (formElements: FormElement[]) => {
      return (
        formElements.find(element => element.data.id === 'current').data
          .value === false
      )
    }
  }
]
